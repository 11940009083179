import {isAfter} from 'date-fns';

export const isGraduatedUser = (
  graduationYear: number,
  graduationMonth: number,
) => {
  return isAfter(
    new Date(),
    new Date(`${graduationYear}/${graduationMonth}/31`),
  );
};
